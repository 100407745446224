import { useState, useEffect } from "react";
import { Input, Alert, Modal, Button } from "antd";
import { toGetCode, toValidateCode } from "../Compte/Api";

const ModalCode = ({
  onOk,
  isAdminModalVisible,
  setAdminModalVisible,
  text
}) => {
  const [adminCode, setAdminCode] = useState("");
  const [code, setCode] = useState("");
  const [wrongAdminCode, setWrongAdminCode] = useState(false);
  const [disabled, setDisabled] = useState(false);
  useEffect(() => {
    toGetCode().then((res) => {
      setCode(res.code);
    });
  }, [isAdminModalVisible, text]);

  const save = () => {
    setDisabled(true);
    toValidateCode(adminCode).then((res) => {
      if (res === -1) {
        setWrongAdminCode(true);
        setDisabled(false);
      } else {
        onOk(res);
      }
    });
  };

  return (
    <Modal
      title="Operation non autorisée"
      visible={isAdminModalVisible}
      footer={[
        <Button key="close" onClick={() => setAdminModalVisible(false)}>
          Cancel
        </Button>,
        <Button key="submit" disabled={disabled} type="primary" onClick={save}>
          Ok
        </Button>
      ]}
      onOk={save}
      onCancel={() => setAdminModalVisible(false)}
    >
      <p>{text}</p>
      {wrongAdminCode && (
        <Alert
          message="Code admin incorrect"
          type="error"
          style={{ marginBottom: 10 }}
        />
      )}
      <Input
        autoComplete="new-password"
        placeholder="Code admin"
        type="password"
        onChange={(e) => setAdminCode(e.target.value)}
      />
    </Modal>
  );
};
export default ModalCode;
