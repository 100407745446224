import { Button, Card, Col, Row, Form, Typography, Select, Spin } from "antd";
import { RightOutlined, LeftOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import "../../../Styles/TwoTables.scss";
import Tecdoc from "../../../Utils/Tecdoc";
import ClientModal from "../../Vente/Clients/ClientModal";
import Article from "../../../Models/Article";
import { toAddOrUpdateArticle, toGetEmplacementList } from "../../Gestions/Api";
import {
  errorMsg,
  successMsg,
  toFormatArticleClassifications,
} from "../../../Utils/Utils";
import ArticleModal from "../../Gestions/ArticleModal";
import history from "../../../Utils/History";
import { connect } from "react-redux";
import {
  addItem,
  clearItems,
  setItemsState,
  setAchat,
  setEmplacement,
} from "../../../Actions/ItemsSelectionAtions";
import { toCreateInventory } from "../APIs";
import { Link, useParams } from "react-router-dom";
import CAxios from "../../../Utils/CAxios";
import moment from "moment";
import localization from "moment/locale/fr";
import { DataExistsException } from "../../../DataExitsException";
import ItemsSelectionInventaire from "../../../Components/ItemsSelectionInventaire";

moment.updateLocale("fr", localization);
const { Option } = Select;

const Inventaire = ({ AppStore, inventaire, ...props }) => {
  const { id } = useParams();
  const [emplacement, setEmplacement] = useState(null);
  const [createModalVisible, setCreateModalVisible] = useState(false);
  const [articleDetails, setArticleDetails] = useState({
    title: "",
    reference: "",
    marque: "",
    type: "Origine",
    categorie: "",
  });

  const [activeTable, setActiveTable] = useState(1);
  const [reference, setReference] = useState("");
  const [emplacements, setemplacements] = useState(null);

  const [marge, setMarge] = useState(-1);
  const [savingState, setSavingState] = useState({
    saving: false,
    print: false,
  });
  const [form] = Form.useForm();

  const [state, setState] = useState({
    createModalVisible: false,
    providerForm: {
      id: -1,
      s__email: "",
      s__fax: "",
      s__matricule: "",
      s__nom: "",
      s__nomInCharge: "",
      s__phone: "",
      s__phoneInCharge: "",
      s__register: "",
    },
    readonly: false,
  });

  useEffect(() => {
    if (AppStore.entityId == -1) {
      history.push("/achat/inventory");
    }
    props.clearItems();
    props.setAchat(true);

    AppStore.setFirst(0);
    AppStore.setSecond(13);

    toGetEmplacementList().then((data) => setemplacements(data));
  }, [id]);

  const onSelectChange = (e) => {
    var p = emplacements.filter((a) => a.title == e.target.value);
    if (p.length > 0) {
      setEmplacement(p[0]);
      props.setEmplacement(p[0]);
    } else {
      setEmplacement(null);
    }
  };

  const saveInventaire = async () => {
    form
      .validateFields()
      .then((res) => {
        var toedit = false;
        if (id) {
          toedit = true;
        }
        if (inventaire.emplacement == null) {
          errorMsg("Selectionnez un emplacement");
          return;
        }
        var res = [];
        inventaire.items.forEach((article) => {
          res.push({
            id: article.id,
            qte: article.quantity,
          });
        });

        setSavingState({ saving: true });
        toCreateInventory(inventaire.emplacement.id, res, AppStore.entityId)
          .then(() => history.push("/achat/inventory/list"))
          .catch(() => {
            setSavingState({ saving: false });
          });
      })
      .catch((errorInfo) => {
        errorMsg("Merci d'ajouter le BL fournisseur");
      });
  };

  const switchTable = () => {
    activeTable === 1 ? setActiveTable(2) : setActiveTable(1);
  };

  const addFromTecdoc = async (article) => {
    var a = new Article();
    a.titre = article.fullName;
    a.reference = article.ref;
    a.marque = article.brand.name;
    a.type = "Adaptable";
    a.categorie = article.fullName;
    a.data = toFormatArticleClassifications(article);
    a.referenceTecdoc = article.ref;
    a.fromTecdoc = true;

    setArticleDetails(a);

    setCreateModalVisible(true);
  };

  const saveArticle = async (id, article) => {
    try {
      var newArticle = await toAddOrUpdateArticle(id, article);
      if (id === -1) {
        setReference(newArticle.s__reference);
        successMsg("Article créé avec succés");
      }
      setCreateModalVisible(false);
    } catch (e) {
      if (e instanceof DataExistsException) {
        errorMsg("L'article existe déja");
      } else {
        errorMsg("Une erreur est survenue");
      }
    }
  };

  const chooseProvider = (providerName, option) => {
    if (providerName === "0")
      setState({
        readonly: false,
        createModalVisible: true,
        providerForm: {
          id: -1,
          s__email: "",
          s__fax: "",
          s__matricule: "",
          s__nom: "",
          s__nomInCharge: "",
          s__phone: "",
          s__phoneInCharge: "",
          s__register: "",
        },
      });
    else {
      props.setEmplacement(option.data);
      setEmplacement(option.data);
    }
  };

  const exitCreateModal = () => {
    setCreateModalVisible(false);
  };

  const updateMarge = (value) => {
    setMarge(value);
  };

  const rightColHeader = (
    <Row justify="space-between">
      <Col md={12}>
        <Typography.Title style={{ marginLeft: "10px" }} level={5}>
          Inventaire
        </Typography.Title>
      </Col>
      <Col md={12} style={{ textAlign: "right" }}>
        <Row gutter={8}>
          <Col span={8}>
            <Typography.Title level={4}>Emplacement :</Typography.Title>
          </Col>
          <Col span={12} style={{ textAlign: "left" }}>
            {/* <Select
              showSearch
              value={
                inventaire.provider != null
                  ? inventaire.provider.s__nom
                  : null
              }
              onSelect={chooseProvider}
              style={{ width: "100%" }}
              placeholder="Cherecher un fournisseur"
              filterOption={(input, option) =>
                option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              <Option value="0">
                <PlusSquareOutlined /> Nouveau fournisseur
              </Option>
              {providers &&
                providers.map((provider) => (
                  <Option
                    key={provider.id}
                    value={provider.s__nom}
                    data={provider}
                  >
                    {provider.s__nom}
                  </Option>
                ))}
                
            </Select> */}
            <input
              list="ice-cream-flavors"
              id="input"
              name="ice-cream-choice"
              onChange={(e) => onSelectChange(e)}
            />

            <datalist id="ice-cream-flavors">
              {emplacements &&
                emplacements.map((emplacement) => (
                  <option
                    key={emplacement.id}
                    value={emplacement.title}
                    data={emplacement}
                  >
                    {emplacement.title}
                  </option>
                ))}
            </datalist>
          </Col>
        </Row>
      </Col>

      {state.clientForm && (
        <ClientModal
          clientForm={state.clientForm}
          visible={state.createModalVisible}
          onCancel={() =>
            setState({
              createModalVisible: false,
              clientForm: null,
              readonly: false,
            })
          }
          onClientSaved={null}
          readonly={state.readonly}
        />
      )}
    </Row>
  );

  return (
    <>
      {/* <Modal
        title="Choisir Emplacement"
        visible={modalEmplacement}
        maskClosable={false}
        onOk={() => emplacement != null && setModalEmplacement(false)}
        onCancel={() => emplacement != null && setModalEmplacement(false)}
      >
        <input
          list="ice-cream-flavors"
          id="input"
          name="ice-cream-choice"
          onChange={(e) => onSelectChange(e)}
        />

        <datalist id="ice-cream-flavors">
          {emplacements &&
            emplacements.map((emplacement) => (
              <option
                key={emplacement.id}
                value={emplacement.title}
                data={emplacement}
              >
                {emplacement.title}
              </option>
            ))}
        </datalist>
      </Modal> */}
      <Row style={{ height: "100%" }} className="two-tables-row">
        <Col lg={12} md={24} className={"articles-list"}>
          <Card>
            <Tecdoc
              addFromTecdoc={addFromTecdoc}
              displayTitle={true}
              addItem={props.addItem}
              reference={reference}
              achat={true}
            />
          </Card>
        </Col>
        <Col
          lg={12}
          md={24}
          className={"selection-list" + (activeTable === 2 ? " active" : "")}
        >
          <Card title={rightColHeader} style={{ minHeight: "100%" }}>
            <ItemsSelectionInventaire
              displayMarge={true}
              getMarge={updateMarge}
            />
            {inventaire.items && inventaire.items.length > 0 && (
              <div className="selection-footer">
                <Row justify="end" gutter={8}>
                  <Col xl={6} lg={8}>
                    <Button
                      onClick={() => saveInventaire()}
                      block
                      type={"primary"}
                      style={{ marginRight: "10px" }}
                      disabled={savingState.saving}
                    >
                      <span
                        style={{
                          marginRight: 10,
                          whiteSpace: "nowrap",
                          maxWidth: "calc(100% - 20px)",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          verticalAlign: !savingState.print
                            ? "text-top"
                            : "baseline",
                        }}
                      >
                        Enregister
                      </span>
                      {savingState.saving && !savingState.print && (
                        <Spin size="small" style={{ marginLeft: 10 }} />
                      )}
                    </Button>
                  </Col>

                  <Col xl={6} lg={8}>
                    <Button block danger onClick={props.clearItems}>
                      <Link to="/achat/reception">Annuler</Link>
                    </Button>
                  </Col>
                </Row>
              </div>
            )}
          </Card>
        </Col>
      </Row>

      <Button
        className={"table-switch" + (activeTable === 2 ? " left" : "")}
        onClick={switchTable}
      >
        {activeTable === 1 ? <RightOutlined /> : <LeftOutlined />}
      </Button>

      <ArticleModal
        saveArticle={saveArticle}
        details={articleDetails}
        createModalVisible={createModalVisible}
        closeCreateModal={exitCreateModal}
      />
    </>
  );
};

const mapStateToProps = ({ itemSelectionState }) => {
  return {
    inventaire: itemSelectionState,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setBRToUpdate: (PurshaseOrder) => dispatch(setItemsState(PurshaseOrder)),
    addItem: (item) => dispatch(addItem(item)),
    setAchat: (value) => dispatch(setAchat(value)),
    setEmplacement: (value) => dispatch(setEmplacement(value)),
    clearItems: () => dispatch(clearItems()),
    setQuoteToUpdate: (quote) => dispatch(setItemsState(quote)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Inventaire);
