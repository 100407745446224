import {
  Typography,
  Row,
  DatePicker,
  Tabs,
  Col,
  Skeleton,
  Card,
  Select,
  Tag,
  Button
} from "antd";
import { useEffect, useState } from "react";
import {
  toGetCAYear,
  toGetCAMonth,
  toGetCAYearAchat,
  toGetCAMonthAchat,
  toGetCAPerYear,
  toGetCAAchatPerYear
} from "./APIs";
import moment from "moment";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  ReferenceLine,
  BarChart,
  Bar,
  LabelList,
  Legend
} from "recharts";
import Loading from "react-loading-bar";
import { toGetUsers } from "../Compte/Api";
import printJS from "print-js";
import Modal from "antd/lib/modal/Modal";
import { getMax } from "../../Utils/Utils";

const { Option } = Select;

const colors = [
  "#00aeef",
  "#5c3494",
  "#108d9a",
  "#72cbf2",
  "#846eb1",
  "#4eb4e6",
  "#694a9e",
  "#5c3494",
  "#19b4bb",
  "#2696d3",
  "#eeedee",
  "#231f20",
  "#432c79",
  "#ef5350"
];

const CA = ({ AppStore, ...props }) => {
  const [data, setData] = useState([]);
  const [dataAchat, setDataAchat] = useState([]);
  const [dataMonth, setDataMonth] = useState([]);
  const [dataMonthAchat, setDataMonthAchat] = useState([]);
  const [loading, setLoading] = useState(true);
  const [y, setY] = useState(0);
  const [yAchat, setYAchat] = useState(0);
  const [yMonth, setYMonth] = useState(0);
  const [yMonthAchat, setYMonthAchat] = useState(0);
  const [users, setUsers] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState(["0"]);
  const [dataYears, setDataYears] = useState([]);
  const [dataYearsAchat, setDataYearsAchat] = useState([]);
  const [month, setMonth] = useState(moment());
  const [max, setMax] = useState(10000);
  const [isOpen, setIsopen] = useState(false);
  const [selectedYears, setSelectedYears] = useState([]);

  useEffect(() => {
    AppStore.setFirst(2);
    AppStore.setSecond(15);
    toGetData(moment().format("YYYY"));
    toGetDataAchat(moment().format("YYYY"));
    toGetDataMonth(moment(), selectedUsers);
    toGetDataMonthAchat(moment());
    toGetUsers().then((users) => {
      setUsers(users);
    });
    onChangeYear([new Date().getFullYear() - 1, new Date().getFullYear()]);
  }, []);

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      var month = moment.months(payload[0].payload.month - 1);
      var v = payload[0].payload.value.toLocaleString(undefined, {
        minimumFractionDigits: 3
      });
      return (
        <div className="app--graph-tooltip">
          <p className="label">{`${month} : ${v}`}</p>
        </div>
      );
    }

    return null;
  };

  const toGetData = (year) => {
    setLoading(true);
    toGetCAYear(year).then((res) => {
      setData(res);
      setLoading(false);
      var total = 0;
      res.forEach((element) => {
        total += parseFloat(element.value);
      });

      setTimeout(() => {
        setLoading(false);
        setY(total / 12);
      }, 500);
    });
  };

  const toGetDataAchat = (year) => {
    setLoading(true);
    toGetCAYearAchat(year).then((res) => {
      setDataAchat(res);
      setLoading(false);
      var total = 0;
      res.forEach((element) => {
        total += parseFloat(element.value);
      });
      setTimeout(() => {
        setLoading(false);
        setYAchat(total / 12);
      }, 500);
    });
  };

  const toGetDataMonth = (date, l__users) => {
    setMonth(date);
    setLoading(true);
    toGetCAMonth(date.format("YYYY"), date.format("MM"), l__users).then(
      (res) => {
        setMax(Math.ceil(getMax(res) + 500));
        setDataMonth(res);
        setLoading(false);
        var total = 0;
        res.forEach((element) => {
          total += parseFloat(element.value);
        });
        setTimeout(() => {
          setLoading(false);
          setYMonth(total / res.length);
        }, 500);
      }
    );
  };

  const toGetDataMonthAchat = (date) => {
    setLoading(true);
    toGetCAMonthAchat(date.format("YYYY"), date.format("MM")).then((res) => {
      setDataMonthAchat(res);
      setLoading(false);
      var total = 0;
      res.forEach((element) => {
        total += parseFloat(element.value);
      });
      setTimeout(() => {
        setLoading(false);
        setYMonthAchat(total / res.length);
      }, 500);
    });
  };

  const changeUsers = (list) => {
    var l__users = [];
    if (list.length === 0) {
      return;
    }
    if (list.includes("0")) {
      l__users.push("0");
    }

    setSelectedUsers(list);
    list.forEach((element) => {
      if (element != 0) {
        l__users.push(users.filter((e) => e.username == element)[0].id);
      }
    });
    setSelectedUsers(l__users);
    toGetDataMonth(month, l__users);
  };

  const getUsername = (el) => {
    if (el == "0") return "Tout";
    return users.filter((e) => e.id == el)[0].username;
  };

  const onChangeYear = (dateString) => {
    setSelectedYears(dateString);
    toGetCAPerYear(dateString).then((res) => {
      setDataYears(res);
    });
    toGetCAAchatPerYear(dateString).then((res) => {
      setDataYearsAchat(res);
    });
  };

  return (
    <>
      <Loading show={loading} color="red" showSpinner={true} />

      <Row className="auto--custom-header">
        <Col span="6">
          <Typography.Title level={3}>
            Chiffres d'affaires Annuel
          </Typography.Title>
        </Col>
        <Col span="4">
          <DatePicker
            onChange={(date, dateString) => {
              toGetDataAchat(dateString);
              toGetData(dateString);
            }}
            picker="year"
          />
        </Col>
      </Row>
      <Skeleton active loading={loading} />

      {data.length > 0 && (
        <Card style={{ marginTop: "1vh" }}>
          <Tabs defaultActiveKey="1">
            <Tabs.TabPane tab="Vente" key="1">
              <Row>
                <Col span="12">
                  <div style={{ height: 400, marginTop: "4vh" }}>
                    <ResponsiveContainer width="100%" height="100%">
                      <BarChart
                        data={data}
                        margin={{
                          top: 10,
                          right: 30,
                          left: 30,
                          bottom: 0
                        }}
                      >
                        <Legend />

                        <CartesianGrid stroke="#ccc" strokeDasharray="3 3" />
                        <Bar radius={4} dataKey="value" fill="#5c3494" />
                        <LabelList
                          dataKey={(v) => parseInt(v.value)}
                          position="insideBottom"
                          angle={270}
                          offset={25}
                          fill="black"
                        />
                        <XAxis
                          dataKey={(v) =>
                            moment.months(v.month - 1).substring(0, 3)
                          }
                        />
                        <YAxis
                          type="number"
                          dataKey={(v) => parseInt(v.value)}
                          domain={[0, "dataMax + 5000"]}
                        />
                        <ReferenceLine
                          y={y}
                          label={"Moyenne: " + Math.floor(y) + " dt"}
                          stroke="red"
                          strokeDasharray="3 3"
                        />

                        <Tooltip
                          cursor={{ fill: "transparent" }}
                          content={<CustomTooltip />}
                        />

                        <Area type="monotone" dataKey="value"></Area>
                      </BarChart>
                    </ResponsiveContainer>
                  </div>
                </Col>

                <Col span="12">
                  <div style={{ height: 400, marginTop: "4vh" }}>
                    <ResponsiveContainer width="100%" height="100%">
                      <AreaChart
                        data={data}
                        margin={{
                          top: 10,
                          right: 30,
                          left: 30,
                          bottom: 0
                        }}
                      >
                        <CartesianGrid stroke="#ccc" strokeDasharray="3 3" />

                        <XAxis
                          dataKey={(v) =>
                            moment.months(v.month - 1).substring(0, 3)
                          }
                        />
                        <YAxis
                          type="number"
                          dataKey={(v) => parseInt(v.value)}
                          domain={[0, "dataMax + 5000"]}
                        />
                        <ReferenceLine
                          y={y}
                          label={"Moyenne: " + Math.floor(y) + " dt"}
                          stroke="red"
                          strokeDasharray="3 3"
                        />

                        <Tooltip content={<CustomTooltip />} />
                        <Area
                          fill="#694a9e"
                          type="monotone"
                          dataKey="value"
                        ></Area>
                      </AreaChart>
                    </ResponsiveContainer>
                  </div>
                </Col>
              </Row>
            </Tabs.TabPane>
            <Tabs.TabPane tab="Achat" key="2">
              <Row>
                <Col span="12">
                  <div style={{ height: 400, marginTop: "4vh" }}>
                    <ResponsiveContainer width="100%" height="100%">
                      <BarChart
                        data={dataAchat}
                        margin={{
                          top: 10,
                          right: 30,
                          left: 30,
                          bottom: 0
                        }}
                      >
                        <CartesianGrid stroke="#ccc" strokeDasharray="3 3" />
                        <Bar radius={4} dataKey="value" fill="#00aeef" />
                        <LabelList
                          dataKey={(v) => parseInt(v.value)}
                          position="insideBottom"
                          angle={270}
                          offset={25}
                          fill="black"
                        />
                        <XAxis
                          dataKey={(v) =>
                            moment.months(v.month - 1).substring(0, 3)
                          }
                        />
                        <YAxis
                          type="number"
                          dataKey={(v) => parseInt(v.value)}
                          domain={[0, "dataMax + 5000"]}
                        />
                        <ReferenceLine
                          y={yAchat}
                          label={"Moyenne: " + Math.floor(yAchat) + " dt"}
                          stroke="red"
                          strokeDasharray="3 3"
                        />

                        <Tooltip
                          cursor={{ fill: "transparent" }}
                          content={<CustomTooltip />}
                        />

                        <Area type="monotone" dataKey="value"></Area>
                      </BarChart>
                    </ResponsiveContainer>
                  </div>
                </Col>

                <Col span="12">
                  <div style={{ height: 400, marginTop: "4vh" }}>
                    <ResponsiveContainer width="100%" height="100%">
                      <AreaChart
                        data={dataAchat}
                        margin={{
                          top: 10,
                          right: 30,
                          left: 30,
                          bottom: 0
                        }}
                      >
                        <CartesianGrid stroke="#ccc" strokeDasharray="3 3" />

                        <XAxis
                          dataKey={(v) =>
                            moment.months(v.month - 1).substring(0, 3)
                          }
                        />
                        <YAxis
                          type="number"
                          dataKey={(v) => parseInt(v.value)}
                          domain={[0, "dataMax + 5000"]}
                        />

                        <ReferenceLine
                          y={yAchat}
                          label={"Moyenne: " + Math.floor(yAchat) + " dt"}
                          stroke="red"
                          strokeDasharray="3 3"
                        />

                        <Tooltip content={<CustomTooltip />} />
                        <Area
                          type="monotone"
                          fill="#2696d3"
                          dataKey="value"
                        ></Area>
                      </AreaChart>
                    </ResponsiveContainer>
                  </div>
                </Col>
              </Row>
            </Tabs.TabPane>
          </Tabs>
        </Card>
      )}

      {/* <Row className="auto--custom-header" style={{ marginTop: "2vh" }}>
        <Col span="8">
          <Typography.Title level={3}>
            Comparaison chiffres d'affaires entre les années
          </Typography.Title>
        </Col>
        <Col span="4">
          <DatePicker.RangePicker
            onChange={(date, dateString) => {
              onChangeYear(dateString);
            }}
            picker="year"
          />
        </Col>
      </Row>
      <Skeleton active loading={loading} />

      {dataYears.length > 0 && (
        <Card style={{ marginTop: "1vh" }}>
          <Tabs defaultActiveKey="1">
            <Tabs.TabPane tab="Vente" key="1">
              <Row>
                <Col span="12">
                  <div style={{ height: 400, marginTop: "4vh" }}>
                    <ResponsiveContainer width="100%" height="100%">
                      <BarChart
                        data={dataYears}
                        margin={{
                          top: 10,
                          right: 30,
                          left: 30,
                          bottom: 0
                        }}
                      >
                        <Legend />

                        <CartesianGrid stroke="#ccc" strokeDasharray="3 3" />
                        {selectedYears.map((el, i) => (
                          <Bar
                            radius={4}
                            dataKey={"value" + el}
                            fill={colors[i]}
                          />
                        ))}

                        <XAxis
                          dataKey={(v) =>
                            moment.months(v.month - 1).substring(0, 3)
                          }
                        />
                        <YAxis type="number" domain={[0, 500000]} />

                        <Tooltip />
                      </BarChart>
                    </ResponsiveContainer>
                  </div>
                </Col>

                <Col span="12">
                  <div style={{ height: 400, marginTop: "4vh" }}>
                    <ResponsiveContainer width="100%" height="100%">
                      <AreaChart
                        data={dataYears}
                        margin={{
                          top: 10,
                          right: 30,
                          left: 30,
                          bottom: 0
                        }}
                      >
                        <CartesianGrid stroke="#ccc" strokeDasharray="3 3" />

                        <XAxis
                          dataKey={(v) =>
                            moment.months(v.month - 1).substring(0, 3)
                          }
                        />
                        <YAxis
                          type="number"
                          dataKey={(v) => parseInt(v.value)}
                          domain={[0, 500000]}
                        />
                        <ReferenceLine
                          y={y}
                          label={"Moyenne: " + Math.floor(y) + " dt"}
                          stroke="red"
                          strokeDasharray="3 3"
                        />

                        <Tooltip />
                        {selectedYears.map((el, i) => (
                          <Area
                            type="monotone"
                            radius={4}
                            dataKey={"value" + el}
                            fill={colors[i]}
                          />
                        ))}
                      </AreaChart>
                    </ResponsiveContainer>
                  </div>
                </Col>
              </Row>
            </Tabs.TabPane>
            {/*<Tabs.TabPane tab="Achat" key="2">
              <Row>
                <Col span="12">
                  <div style={{ height: 400, marginTop: "4vh" }}>
                    <ResponsiveContainer width="100%" height="100%">
                      <BarChart
                        dataYears={dataYearsAchat}
                        margin={{
                          top: 10,
                          right: 30,
                          left: 30,
                          bottom: 0
                        }}
                      >
                        <CartesianGrid stroke="#ccc" strokeDasharray="3 3" />
                        {selectedYears.map((el, i) => (
                          <Bar
                            radius={4}
                            dataKey={"value" + el}
                            fill={colors[i]}
                          />
                        ))}{" "}
                        <LabelList
                          dataKey={(v) => parseInt(v.value)}
                          position="insideBottom"
                          angle={270}
                          offset={25}
                          fill="black"
                        />
                        <XAxis
                          dataKey={(v) =>
                            moment.months(v.month - 1).substring(0, 3)
                          }
                        />
                        <YAxis
                          type="number"
                          dataKey={(v) => parseInt(v.value)}
                          domain={[0, "dataYearsMax + 5000"]}
                        />
                        <Tooltip
                          cursor={{ fill: "transparent" }}
                          content={<CustomTooltip />}
                        />
                        <Area type="monotone" dataKey="value"></Area>
                      </BarChart>
                    </ResponsiveContainer>
                  </div>
                </Col>

                <Col span="12">
                  <div style={{ height: 400, marginTop: "4vh" }}>
                    <ResponsiveContainer width="100%" height="100%">
                      <AreaChart
                        dataYears={dataYearsAchat}
                        margin={{
                          top: 10,
                          right: 30,
                          left: 30,
                          bottom: 0
                        }}
                      >
                        <CartesianGrid stroke="#ccc" strokeDasharray="3 3" />

                        <XAxis
                          dataKey={(v) =>
                            moment.months(v.month - 1).substring(0, 3)
                          }
                        />
                        <YAxis
                          type="number"
                          dataKey={(v) => parseInt(v.value)}
                          domain={[0, "dataYearsMax + 5000"]}
                        />

                        <ReferenceLine
                          y={yAchat}
                          label={"Moyenne: " + Math.floor(yAchat) + " dt"}
                          stroke="red"
                          strokeDasharray="3 3"
                        />

                        <Tooltip content={<CustomTooltip />} />
                        <Area
                          type="monotone"
                          fill="#2696d3"
                          dataKey="value"
                        ></Area>
                      </AreaChart>
                    </ResponsiveContainer>
                  </div>
                </Col>
              </Row>
            </Tabs.TabPane>
            
          </Tabs>
        </Card>
      )} */}

      <Row className="auto--custom-header" style={{ marginTop: "2vh" }}>
        <Col span="6">
          <Typography.Title level={3}>
            Chiffres d'affaires mensuel
          </Typography.Title>
        </Col>
        <Col span="4">
          <DatePicker
            onChange={(date, dateString) => {
              toGetDataMonthAchat(date);
              toGetDataMonth(date, selectedUsers);
            }}
            picker="month"
          />
        </Col>
        <Col span="4">
          <Select
            showSearch
            defaultValue="0"
            mode="multiple"
            allowClear
            onChange={(e) => changeUsers(e)}
            style={{ width: "80%" }}
          >
            <Option value="0">Tout</Option>

            {users
              .filter((e) => e.active)
              .map((u) => (
                <Option value={u.username}>{u.username}</Option>
              ))}
          </Select>
        </Col>
      </Row>
      <Skeleton active loading={loading} />

      {dataMonth.length > 0 && (
        <Card style={{ marginTop: "1vh" }}>
          <Tabs defaultActiveKey="1">
            <Tabs.TabPane tab="Vente" key="1">
              {/* <Button
                style={{ float: "right" }}
                type="primary"
                onClick={() => setIsopen(true)}
              >
                Imprimer
              </Button> */}
              <Row>
                <Col span="12">
                  <div style={{ height: 400, marginTop: "4vh" }}>
                    <ResponsiveContainer width="100%" height="100%">
                      <BarChart
                        data={dataMonth}
                        margin={{
                          top: 10,
                          right: 30,
                          left: 50,
                          bottom: 0
                        }}
                      >
                        <CartesianGrid stroke="#ccc" strokeDasharray="3 3" />
                        {selectedUsers.map((el, i) => (
                          <Bar
                            radius={4}
                            dataKey={getUsername(el)}
                            fill={colors[i]}
                          />
                        ))}

                        <XAxis dataKey="day" />
                        <YAxis type="number" domain={[0, 50000]} />
                        <ReferenceLine
                          y={yMonth}
                          label={"Moyenne: " + Math.floor(yMonth) + " dt"}
                          stroke="red"
                          strokeDasharray="3 3"
                        />

                        <Tooltip />
                      </BarChart>
                    </ResponsiveContainer>
                  </div>
                </Col>

                <Col span="12">
                  <div style={{ height: 400, marginTop: "4vh" }}>
                    <ResponsiveContainer width="100%" height="100%">
                      <AreaChart
                        data={dataMonth}
                        margin={{
                          top: 10,
                          right: 30,
                          left: 50,
                          bottom: 0
                        }}
                      >
                        <CartesianGrid stroke="#ccc" strokeDasharray="3 3" />

                        <XAxis dataKey="day" />
                        <YAxis
                          type="number"
                          dataKey={(v) => parseInt(v.value)}
                          domain={[0, 50000]}
                        />
                        <ReferenceLine
                          y={yMonth}
                          label={"Moyenne: " + Math.floor(yMonth) + " dt"}
                          stroke="red"
                          strokeDasharray="3 3"
                        />

                        <Tooltip />
                        {selectedUsers.map((el, i) => (
                          <Area
                            type="monotone"
                            dataKey={getUsername(el)}
                            stroke={colors[i]}
                            fill={colors[i]}
                            stackId={i}
                          ></Area>
                        ))}
                      </AreaChart>
                    </ResponsiveContainer>
                  </div>
                </Col>
              </Row>
              <Row justify="center">
                {selectedUsers.map((el, i) => (
                  <Tag color={colors[i]}>{getUsername(el)}</Tag>
                ))}
              </Row>
            </Tabs.TabPane>
            <Tabs.TabPane tab="Achat" key="2">
              <Row>
                <Col span="12">
                  <div style={{ height: 400, marginTop: "4vh" }}>
                    <ResponsiveContainer width="100%" height="100%">
                      <BarChart
                        data={dataMonthAchat}
                        margin={{
                          top: 10,
                          right: 30,
                          left: 30,
                          bottom: 0
                        }}
                      >
                        <CartesianGrid stroke="#ccc" strokeDasharray="3 3" />
                        <Bar radius={4} dataKey="value" fill="#00aeef" />
                        <LabelList
                          dataKey={(v) => parseInt(v.value)}
                          position="insideBottom"
                          angle={270}
                          offset={25}
                          fill="black"
                        />
                        <XAxis dataKey="day" />
                        <YAxis
                          type="number"
                          dataKey={(v) => parseInt(v.value)}
                          domain={[0, "dataMax + 5000"]}
                        />
                        <ReferenceLine
                          y={yMonthAchat}
                          label={"Moyenne: " + Math.floor(yMonthAchat) + " dt"}
                          stroke="red"
                          strokeDasharray="3 3"
                        />

                        <Tooltip />

                        <Area type="monotone" dataKey="value"></Area>
                      </BarChart>
                    </ResponsiveContainer>
                  </div>
                </Col>

                <Col span="12">
                  <div style={{ height: 400, marginTop: "4vh" }}>
                    <ResponsiveContainer width="100%" height="100%">
                      <AreaChart
                        data={dataMonthAchat}
                        margin={{
                          top: 10,
                          right: 30,
                          left: 30,
                          bottom: 0
                        }}
                      >
                        <CartesianGrid stroke="#ccc" strokeDasharray="3 3" />

                        <XAxis dataKey="day" />
                        <YAxis
                          type="number"
                          dataKey={(v) => parseInt(v.value)}
                          domain={[0, "dataMax + 5000"]}
                        />
                        <ReferenceLine
                          y={yMonthAchat}
                          label={"Moyenne: " + Math.floor(yMonthAchat) + " dt"}
                          stroke="red"
                          strokeDasharray="3 3"
                        />

                        <Tooltip />
                        <Area
                          fill="#2696d3"
                          type="monotone"
                          dataKey="value"
                        ></Area>
                      </AreaChart>
                    </ResponsiveContainer>
                  </div>
                </Col>
              </Row>
            </Tabs.TabPane>
          </Tabs>
          <Modal
            className="smtv-modal"
            visible={isOpen}
            onOk={() => printJS("toPrint", "html")}
            onCancel={() => setIsopen(false)}
          >
            <div id="toPrint">
              <Row>
                <Col span="24">
                  <div style={{ height: 400, marginTop: "2vh" }}>
                    <ResponsiveContainer width="100%" height="100%">
                      <BarChart
                        data={dataMonth}
                        margin={{
                          top: 10,
                          right: 30,
                          left: 50,
                          bottom: 0
                        }}
                      >
                        <CartesianGrid stroke="#ccc" strokeDasharray="3 3" />
                        {selectedUsers.map((el, i) => (
                          <Bar
                            radius={4}
                            dataKey={getUsername(el)}
                            fill={colors[i]}
                          />
                        ))}

                        <XAxis dataKey="day" />
                        <YAxis type="number" domain={[0, max]} />
                        <ReferenceLine
                          y={yMonth}
                          label={"Moyenne: " + Math.floor(yMonth) + " dt"}
                          stroke="red"
                          strokeDasharray="3 3"
                        />

                        <Tooltip />
                      </BarChart>
                    </ResponsiveContainer>
                  </div>
                </Col>

                <Col span="24">
                  <div style={{ height: 400, marginTop: "2vh" }}>
                    <ResponsiveContainer width="100%" height="100%">
                      <AreaChart
                        data={dataMonth}
                        margin={{
                          top: 10,
                          right: 30,
                          left: 50,
                          bottom: 0
                        }}
                      >
                        <CartesianGrid stroke="#ccc" strokeDasharray="3 3" />

                        <XAxis dataKey="day" />
                        <YAxis
                          type="number"
                          dataKey={(v) => parseInt(v.value)}
                          domain={[0, max]}
                        />
                        <ReferenceLine
                          y={yMonth}
                          label={"Moyenne: " + Math.floor(yMonth) + " dt"}
                          stroke="red"
                          strokeDasharray="3 3"
                        />

                        <Tooltip />
                        {selectedUsers.map((el, i) => (
                          <Area
                            type="monotone"
                            dataKey={getUsername(el)}
                            stroke={colors[i]}
                            fill={colors[i]}
                            stackId={i}
                          ></Area>
                        ))}
                      </AreaChart>
                    </ResponsiveContainer>
                  </div>
                </Col>
              </Row>
              <Row justify="center">
                {selectedUsers.map((el, i) => (
                  <div
                    style={{
                      width: "50px",
                      backgroundColor: colors[i],
                      textAlign: "center",
                      color: "#fff"
                    }}
                  >
                    {getUsername(el)}
                  </div>
                ))}
              </Row>
            </div>
          </Modal>
        </Card>
      )}
    </>
  );
};

export default CA;
