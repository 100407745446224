import { Row, Col, Button } from "antd";
import history from "../Utils/History";
import cookie from "react-cookies";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const CustomMenu = ({ AppStore }) => {
  useEffect(() => {}, [AppStore.first, AppStore.second]);
  const setSelected = (i) => {
    AppStore.setSecond(i);
  };
  return (
    <>
      {AppStore.first == 0 && (
        <Row className="auto--new-menu-achat" justify={"center"} gutter={24}>
          <Col span="3" style={{ textAlign: "left" }}>
            <Link to="/">
              <img src="/images/logo-original.png" alt="smtv-logo" />
            </Link>
          </Col>
          <Col
            span={3}
            className={
              AppStore.second == 0
                ? "auto--button-menu auto--button-menu-selecetd"
                : "auto--button-menu"
            }
            onClick={() => {
              setSelected(0);
              history.push("/providers");
            }}
          >
            <Link to="/providers">
              <img className="auto--img-button" src="/images/client.svg" />
              <br />
              <span>Fournisseurs</span>
            </Link>
          </Col>
          <Col
            span={3}
            className={
              AppStore.second == 1
                ? "auto--button-menu auto--button-menu-selecetd"
                : "auto--button-menu"
            }
            onClick={() => {
              setSelected(1);
              history.push("/achat/purshaseOrders/list");
            }}
          >
            <Link to="/achat/purshaseOrders/list">
              <img className="auto--img-button" src="/images/boncommande.png" />
              <br />
              <span>Bon commande</span>
            </Link>
          </Col>
          <Col
            span={3}
            className={
              AppStore.second == 2
                ? "auto--button-menu auto--button-menu-selecetd"
                : "auto--button-menu"
            }
            onClick={() => {
              setSelected(2);
              history.push("/achat/reception/list");
            }}
          >
            <Link to="/achat/reception/list">
              <img className="auto--img-button" src="/images/reception.png" />
              <br />
              <span>Réception</span>
            </Link>
          </Col>
          <Col
            span={3}
            className={
              AppStore.second == 3
                ? "auto--button-menu auto--button-menu-selecetd"
                : "auto--button-menu"
            }
            onClick={() => {
              setSelected(3);
              history.push("/achat/factures");
            }}
          >
            <Link to="/achat/factures">
              <img className="auto--img-button" src="/images/facture.svg" />
              <br />
              <span>Factures</span>
            </Link>
          </Col>
          <Col
            span={3}
            className={
              AppStore.second == 4
                ? "auto--button-menu auto--button-menu-selecetd"
                : "auto--button-menu"
            }
            onClick={() => {
              setSelected(4);
              history.push("/achat/reglements");
            }}
          >
            <Link to="/achat/reglements">
              <img className="auto--img-button" src="/images/reglement.png" />
              <br />
              <span>Rég Fournisseur</span>
            </Link>
          </Col>
          <Col
            onClick={() => {
              setSelected(5);
              history.push("/produits");
            }}
            span={3}
            className={
              AppStore.second == 5
                ? "auto--button-menu auto--button-menu-selecetd"
                : "auto--button-menu"
            }
          >
            <Link to="/produits">
              <img className="auto--img-button" src="/images/produits.png" />
              <br />
              <span>Produits</span>
            </Link>
          </Col>
          <Col
            onClick={() => {
              setSelected(13);
              history.push("/achat/inventory");
            }}
            span={3}
            className={
              AppStore.second == 13
                ? "auto--button-menu auto--button-menu-selecetd"
                : "auto--button-menu"
            }
          >
            <Link to="/produits">
              <img className="auto--img-button" src="/images/produits.png" />
              <br />
              <span>Inventaire</span>
            </Link>
          </Col>
        </Row>
      )}
      {AppStore.first == 1 && (
        <Row className="auto--new-menu-vente" justify={"center"} gutter={24}>
          <Col span="3" style={{ textAlign: "left" }}>
            <Link to="/">
              <img
                src="/images/logo-original.png"
                style={{ marginTop: "1vh" }}
                alt="smtv-logo"
              />
            </Link>
          </Col>

          <Col
            span={3}
            className={
              AppStore.second == 6
                ? "auto--button-menu auto--button-menu-selecetd"
                : "auto--button-menu"
            }
            onClick={() => {
              setSelected(6);
              history.push("/clients");
            }}
          >
            <Link to="/clients">
              <img className="auto--img-button" src="/images/client.png" />
              <br />
              <span>Clients</span>
            </Link>
          </Col>
          <Col
            span={3}
            className={
              AppStore.second == 7
                ? "auto--button-menu auto--button-menu-selecetd"
                : "auto--button-menu"
            }
            onClick={() => {
              setSelected(7);
              history.push("/vente/devis/list");
            }}
          >
            <Link to="/vente/devis/list">
              <img className="auto--img-button" src="/images/boncommande.png" />
              <br />
              <span>Devis</span>
            </Link>
          </Col>
          <Col
            span={3}
            className={
              AppStore.second == 8
                ? "auto--button-menu auto--button-menu-selecetd"
                : "auto--button-menu"
            }
            onClick={() => {
              setSelected(8);
              history.push("/vente/bl/list");
            }}
          >
            <Link to="/vente/bl/list">
              <img
                className="auto--img-button"
                src="/images/bonlivraison.png"
              />
              <br />
              <span>Bon livraison</span>
            </Link>
          </Col>
          <Col
            span={3}
            className={
              AppStore.second == 9
                ? "auto--button-menu auto--button-menu-selecetd"
                : "auto--button-menu"
            }
            onClick={() => {
              setSelected(9);
              history.push("/vente/factures");
            }}
          >
            <Link to="/vente/factures">
              <img className="auto--img-button" src="/images/facture.svg" />
              <br />
              <span>Factures</span>
            </Link>
          </Col>
          <Col
            span={3}
            className={
              AppStore.second == 10
                ? "auto--button-menu auto--button-menu-selecetd"
                : "auto--button-menu"
            }
            onClick={() => {
              setSelected(10);
              history.push("/vente/reglements");
            }}
          >
            <Link to="/vente/reglements">
              <img className="auto--img-button" src="/images/reglement.png" />
              <br />
              <span>Rég Client</span>
            </Link>
          </Col>
          <Col
            span={3}
            className={
              AppStore.second == 11
                ? "auto--button-menu auto--button-menu-selecetd"
                : "auto--button-menu"
            }
            onClick={() => {
              setSelected(11);
              history.push("/produits");
            }}
          >
            <Link to="/produits">
              <img className="auto--img-button" src="/images/produits.png" />
              <br />
              <span>Produits</span>
            </Link>
          </Col>
        </Row>
      )}
      {AppStore.first == 2 && cookie.load("role") == "Super Admin" && (
        <Row className="auto--new-menu-users" gutter={24}>
          <Col span="3" style={{ textAlign: "left" }}>
            <Link to="/">
              <img
                src="/images/logo-original.png"
                style={{ marginTop: "1vh" }}
                alt="smtv-logo"
              />
            </Link>
          </Col>
          <Col
            span={3}
            className={
              AppStore.second == 12
                ? "auto--button-menu auto--button-menu-selecetd"
                : "auto--button-menu"
            }
            onClick={() => {
              setSelected(12);
              history.push("/objectif");
            }}
          >
            <Link to="/objectif">
              <img className="auto--img-button" src="/images/objectif.png" />
              <br />
              <span>Objectifs</span>
            </Link>
          </Col>

          <Col
            span={3}
            className={
              AppStore.second == 13
                ? "auto--button-menu auto--button-menu-selecetd"
                : "auto--button-menu"
            }
            onClick={() => {
              setSelected(13);
              history.push("/caisse");
            }}
          >
            <Link to="/caisse">
              <img className="auto--img-button" src="/images/caisse.png" />
              <br />
              <span>Caisse</span>
            </Link>
          </Col>

          <Col
            span={3}
            className={
              AppStore.second == 14
                ? "auto--button-menu auto--button-menu-selecetd"
                : "auto--button-menu"
            }
            onClick={() => {
              setSelected(14);
              history.push("/stats");
            }}
          >
            <Link to="/stats">
              <img className="auto--img-button" src="/images/objectif.png" />
              <br />
              <span>Statistiques</span>
            </Link>
          </Col>
          <Col
            span={3}
            className={
              AppStore.second == 15
                ? "auto--button-menu auto--button-menu-selecetd"
                : "auto--button-menu"
            }
            onClick={() => {
              setSelected(15);
              history.push("/ca");
            }}
          >
            <Link to="/ca">
              <img className="auto--img-button" src="/images/objectif.png" />
              <br />
              <span>Chiffres d'affaires</span>
            </Link>
          </Col>
        </Row>
      )}
    </>
  );
};

export default CustomMenu;
