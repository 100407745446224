import { Button, Card, Col, Row, Typography, Spin } from "antd";
import {
  RightOutlined,
  LeftOutlined,
  PlusSquareOutlined,
  InfoCircleTwoTone,
} from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import cookie from "react-cookies";

import "../../../Styles/TwoTables.scss";
import Tecdoc from "../../../Utils/Tecdoc";
import ProviderModal from "../Fournisseurs/ProviderModal";
import Article from "../../../Models/Article";
import { toAddOrUpdateArticle } from "../../Gestions/Api";
import {
  errorMsg,
  successMsg,
  toFormatArticleClassifications,
} from "../../../Utils/Utils";
import ArticleModal from "../../Gestions/ArticleModal";
import {
  toCreatePurshaseOrder,
  toPrintPurshaseOrder,
  toEditPurshaseOrder,
  toGetProviderList,
  toGetPurshaseOrder,
} from "../APIs";
import history from "../../../Utils/History";
import { connect } from "react-redux";
import ItemsSelection from "../../../Components/ItemsSelection";
import {
  addItem,
  clearItems,
  setProvider,
  setItemsState,
  setAchat,
} from "../../../Actions/ItemsSelectionAtions";
import { Link, useParams, useRouteMatch } from "react-router-dom";
import ls from "localstorage-ttl";
import CAxios from "../../../Utils/CAxios";
import { DataExistsException } from "../../../DataExitsException";
import Select from "react-select";

const PurshaseOrders = ({ AppStore, boncommande, ...props }) => {
  const updatingPurshaseOrder = useRouteMatch(
    "/achat/purshaseOrder/modifier/:id"
  );
  const { id } = useParams();
  const [createModalVisible, setCreateModalVisible] = useState(false);
  const [marge, setMarge] = useState(-1);
  const [articleDetails, setArticleDetails] = useState({
    title: "",
    reference: "",
    marque: "",
    type: "Origine",
    categorie: "",
  });

  const [state, setState] = useState({
    createModalVisible: false,
    providerForm: {
      id: -1,
      s__email: "",
      s__fax: "",
      s__matricule: "",
      s__nom: "",
      s__nomInCharge: "",
      s__phone: "",
      s__phoneInCharge: "",
      s__register: "",
    },
    readonly: false,
  });
  const [provider, setProvider] = useState(null);
  const [activeTable, setActiveTable] = useState(1);
  const [reference, setReference] = useState("");
  const [providers, setProviders] = useState(null);
  const [ProviderModalState, setProviderModalState] = useState({
    visible: false,
    provider: {
      id: -1,
      s__email: "",
      s__fax: "",
      s__matricule: "",
      s__nom: "",
      s__nomInCharge: "",
      s__phone: "",
      s__phoneInCharge: "",
      s__register: "",
    },
  });

  const options = [
    { value: "chocolate", label: "Chocolate" },
    { value: "strawberry", label: "Strawberry" },
    { value: "vanilla", label: "Vanilla" },
  ];

  const [savingState, setSavingState] = useState({
    saving: false,
    print: false,
  });

  useEffect(() => {
    props.clearItems();

    AppStore.setFirst(0);
    AppStore.setSecond(1);

    if (null != ls.get("providers")) {
      setProviders(ls.get("providers"));
    }
    toGetProviderList().then((data) => setProviders(data));

    if (null != id) {
      toGetPurshaseOrder(id).then((PurshaseOrder) => {
        const PurshaseOrderToUpdate = {
          id: PurshaseOrder.id,
          total: PurshaseOrder.d__total,
          discount: PurshaseOrder.d__discount,
          items: [],
          provider: PurshaseOrder.provider,
        };
        for (let item of PurshaseOrder.purshase__orderItems) {
          PurshaseOrderToUpdate.items.push({
            ...item,
            ref: item.article.s__reference,
            titre: item.article.s__secondTitle,
            quantity: item.d__quantity,
            marge: item.d__marge,
            uPriceHt: item.d__unitPriceHT,
            uPriceTTC: item.d__unitPriceTTC,
            total: item.d__total,
            discount: item.d__discount,
            prix_achat: item.article.l__prixAchatTTC,
            prix_unitaire: item.article.l__prixVenteHT,
            prix_unitaireTTC: item.article.l__prixVenteTTC,
            prix_unitaire_gros: item.article.l__prixVenteGrosHT,
            prix_unitaire_grosTTC: item.article.l__prixVenteGrosTTC,
            tva: item.article.tva.title,
          });
        }
        props.setPurshaseOrderToUpdate(PurshaseOrderToUpdate);
      });
    }
    props.setAchat(true);
  }, []);

  const saveProvider = (provider) => {
    setProviders(providers.concat(provider));
    chooseProvider(provider.s__nom, { data: provider });
    setProviderModalState({ visible: false, provider: null });
  };

  const savePurshaseOrder = async (print = false) => {
    if (boncommande.provider == null) {
      errorMsg("Selectionnez un fournisseur");
      return;
    }

    setSavingState({ saving: true, print });
    try {
      let res;
      if (updatingPurshaseOrder) {
        res = await toEditPurshaseOrder(
          parseInt(id),
          boncommande.provider,
          boncommande.items,
          boncommande.discount
        );
      } else {
        res = await toCreatePurshaseOrder(
          boncommande.provider,
          boncommande.items,
          boncommande.discount
        );
      }
      const { data } = res;
      setSavingState({ saving: false, print: false });
      props.clearItems();
      history.push("/achat/PurshaseOrders/list");
      if (print) {
        let { PurshaseOrder: newboncommande } = data.success;
        newboncommande = JSON.parse(newboncommande);
        toPrintPurshaseOrder(newboncommande.id);
      }
    } catch (e) {
      errorMsg("Une erreur est survenue");
      setSavingState({ saving: false, print: false });
    }
  };

  const switchTable = () => {
    activeTable === 1 ? setActiveTable(2) : setActiveTable(1);
  };

  const addFromTecdoc = async (article) => {
    var a = new Article();
    a.titre = article.fullName;
    a.reference = article.ref;
    a.marque = article.brand.name;
    a.type = "Adaptable";
    a.categorie = article.fullName;
    a.data = toFormatArticleClassifications(article);
    a.referenceTecdoc = article.ref;
    a.fromTecdoc = true;

    setArticleDetails(a);

    setCreateModalVisible(true);
  };

  const saveArticle = async (id, article) => {
    try {
      var newArticle = await toAddOrUpdateArticle(id, article);
      if (id === -1) {
        setReference(newArticle.s__reference);
        successMsg("Article créé avec succés");
      }
      setCreateModalVisible(false);
    } catch (e) {
      if (e instanceof DataExistsException) {
        errorMsg("L'article existe déja");
      } else {
        errorMsg("Une erreur est survenue");
      }
    }
  };

  const chooseProvider = (providerName, option) => {
    if (providerName === "0")
      setProviderModalState({
        visible: true,
        provider: {
          id: -1,
          s__email: "",
          s__fax: "",
          s__matricule: "",
          s__nom: "",
          s__nomInCharge: "",
          s__phone: "",
          s__phoneInCharge: "",
          s__register: "",
        },
      });
    else {
      props.setProvider(option.data);
      setProvider(option.data);
    }
  };

  const onSelectChange = (e) => {
    var p = providers[e.target.selectedIndex];
    props.setProvider(p);
    setProvider(p);
  };

  const exitCreateModal = () => {
    setCreateModalVisible(false);
  };

  const openReadOnlyModal = () =>
    setState({
      providerForm: provider,
      createModalVisible: true,
      readonly: true,
    });

  const closeProviderModal = () => {
    setProviderModalState({ createModalVisible: false, providerForm: null });
  };

  const updateMarge = (value) => {
    setMarge(value);
  };

  const rightColHeader = (
    <Row justify="space-between">
      <Col md={12}>
        <Row gutter={8}>
          <Col span={12}>
            <Typography.Title style={{ marginLeft: "10px" }} level={5}>
              Bon de commande
            </Typography.Title>
          </Col>
          <Col span={12}>
            <div style={{}}>
              {/*marge != -1 && (
                <>
                  <span style={{ marginLeft: "10%" }}>{marge + " %"}</span>
                  <br />
                  <GaugeChart
                    id="gauge-chart5"
                    nrOfLevels={100}
                    arcsLength={[0.1, 0.25, 0.65]}
                    colors={["#EA4228", "#F5CD19", "#5BE12C"]}
                    percent={marge / 100}
                    textColor={"transparent"}
                    arcPadding={0.01}
                    style={{ height: "50%", width: "50%" }}
                  ></GaugeChart>
                </>
              )*/}
            </div>
          </Col>
        </Row>
      </Col>
      <Col md={12} style={{ textAlign: "right" }}>
        <Row gutter={8}>
          <Col span={8}>
            <Typography.Title level={4}>Fournisseur :</Typography.Title>
          </Col>
          <Col span={4}>
            <Button
              icon={<InfoCircleTwoTone />}
              onClick={() => openReadOnlyModal()}
            />
          </Col>
          <Col span={10} style={{ textAlign: "left" }}>
            {/*<Select
              showSearch
              value={
                boncommande.provider != null
                  ? boncommande.provider.s__nom
                  : null
              }
              onSelect={chooseProvider}
              style={{ width: "100%" }}
              placeholder="Cherecher un fournisseur"
              filterOption={(input, option) =>
                option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              <Option value="0">
                <PlusSquareOutlined /> Nouveau fournisseur
              </Option>
              {providers &&
                providers.map((provider) => (
                  <Option
                    key={provider.id}
                    value={provider.s__nom}
                    data={provider}
                  >
                    {provider.s__nom}
                  </Option>
                ))}
            </Select>
           */}{" "}
            <select onChange={(e) => onSelectChange(e)}>
              {providers &&
                providers.map((provider) => (
                  <option
                    key={provider.id}
                    value={provider.s__nom}
                    data={provider}
                  >
                    {provider.s__nom}
                  </option>
                ))}
            </select>
          </Col>
        </Row>
      </Col>
      {state.providerForm && (
        <ProviderModal
          providerForm={state.providerForm}
          visible={state.createModalVisible}
          exitCreateModal={() =>
            setState({
              createModalVisible: false,
              providerForm: null,
              readonly: false,
            })
          }
          onProviderSaved={null}
          readonly={state.readonly}
        />
      )}
    </Row>
  );

  return (
    <>
      <Row style={{ height: "100%" }} className="two-tables-row">
        <Col lg={12} md={24} className={"articles-list"}>
          <Card>
            <Tecdoc
              addFromTecdoc={addFromTecdoc}
              displayTitle={true}
              achat={true}
              addItem={props.addItem}
              reference={reference}
            />
          </Card>
        </Col>
        <Col
          lg={12}
          md={24}
          className={"selection-list" + (activeTable === 2 ? " active" : "")}
        >
          <Card title={rightColHeader} style={{ minHeight: "100%" }}>
            <ItemsSelection getMarge={updateMarge} displayMarge={true} />
            {boncommande.items && boncommande.items.length > 0 && (
              <div className="selection-footer">
                <Row justify="end" gutter={8}>
                  <Col xl={6} lg={8}>
                    <Button
                      onClick={() => savePurshaseOrder()}
                      block
                      type={"primary"}
                      style={{ marginRight: "10px" }}
                      disabled={savingState.saving}
                    >
                      <span
                        style={{
                          marginRight: 10,
                          whiteSpace: "nowrap",
                          maxWidth: "calc(100% - 20px)",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          verticalAlign: !savingState.print
                            ? "text-top"
                            : "baseline",
                        }}
                      >
                        Enregister
                      </span>
                      {savingState.saving && !savingState.print && (
                        <Spin size="small" style={{ marginLeft: 10 }} />
                      )}
                    </Button>
                  </Col>
                  <Col xl={6} lg={8}>
                    <Button
                      block
                      onClick={() => savePurshaseOrder(true)}
                      disabled={savingState.saving}
                      style={{ marginRight: 10 }}
                    >
                      <span
                        style={{
                          whiteSpace: "nowrap",
                          maxWidth: "calc(100% - 20px)",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          verticalAlign: savingState.print
                            ? "text-top"
                            : "baseline",
                        }}
                      >
                        Enregistrer et Imprimer{" "}
                      </span>
                      {savingState.print && (
                        <Spin size="small" style={{ marginLeft: 10 }} />
                      )}
                    </Button>
                  </Col>
                  <Col xl={6} lg={8}>
                    <Button block danger onClick={props.clearItems}>
                      <Link to="/achat/PurshaseOrders">Annuler</Link>
                    </Button>
                  </Col>
                </Row>
              </div>
            )}
          </Card>
        </Col>
      </Row>

      <Button
        className={"table-switch" + (activeTable === 2 ? " left" : "")}
        onClick={switchTable}
      >
        {activeTable === 1 ? <RightOutlined /> : <LeftOutlined />}
      </Button>
      {ProviderModalState.provider && (
        <ProviderModal
          visible={ProviderModalState.visible}
          providerForm={ProviderModalState.provider}
          onProviderSaved={saveProvider}
          exitCreateModal={closeProviderModal}
        />
      )}

      <ArticleModal
        saveArticle={saveArticle}
        details={articleDetails}
        createModalVisible={createModalVisible}
        closeCreateModal={exitCreateModal}
      />
    </>
  );
};

const mapStateToProps = ({ itemSelectionState }) => {
  return {
    boncommande: itemSelectionState,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addItem: (item) => dispatch(addItem(item)),
    setProvider: (provider) => dispatch(setProvider(provider)),
    clearItems: () => dispatch(clearItems()),
    setAchat: (value) => dispatch(setAchat(value)),
    setPurshaseOrderToUpdate: (PurshaseOrder) =>
      dispatch(setItemsState(PurshaseOrder)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PurshaseOrders);
