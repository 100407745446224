import { message } from "antd";
import moment from "moment";
import localization from "moment/locale/fr";
import { toGetProviderList } from "../Pages/Achat/APIs";
import { toGetArticlesList } from "../Pages/Gestions/Api";
import { toGetClientsList } from "../Pages/Vente/APIs";
moment.updateLocale("fr", localization);
const ls = require("localstorage-ttl");

export const successMsg = (msg) => {
  message.success({
    content: msg,
    style: {
      className: "auto--notif"
    }
  });
};
export const getMax = (a) => {
  return Math.max(
    ...a.map((e) => (Array.isArray(e) ? getMax(e) : parseFloat(e.valeur)))
  );
};
export const toFormatArticleClassifications = (article) => {
  var details = [];

  if (null != article.classifications) {
    article.classifications.map((e) => {
      e.features.map((feature) => {
        var featureUnit =
          null != feature.featureUnit ? feature.featureUnit.symbol : "";

        var value = feature.featureValues[0].value + " " + featureUnit;
        details.push({
          name: feature.name,
          value: value
        });
      });
    });
  }
  return details;
};

export const errorMsg = (msg) => {
  message.error({
    content: msg,
    className: "auto--notif"
  });
};

export const toFormData = (list) => {
  var res = [];
  for (var i = 0; i < list.length; i++) {}
};

export const toFormatArticle = (article) => {
  return {
    key: article.id,
    id: article.id,
    titre: article.s__secondTitle,
    titre1: article.s__firstTitle,
    barcode: article.s__barCode,
    marque: article.marque,
    emplacements: null != article.emplacement ? article.emplacement.title : "",
    prix_achat: article.l__prixAchatTTC,
    prix_achatHT: article.l__prixAchatHT,
    prix_unitaire: article.l__prixVenteHT,
    prix_unitaireTTC: article.l__prixVenteTTC,
    prix_unitaire_gros: article.l__prixVenteGrosHT,
    prix_unitaire_grosTTC: article.l__prixVenteGrosTTC,
    qte_stock: article.stock,
    ref: article.s__reference,
    tva: article.tva,
    marge: article.l__marge,
    margeValue: article.l__margeValue,
    seuil: article.l__seuil,
    article: article
  };
};

export const verifyAdminCode = (code) => {
  if (code === "1920") return Promise.resolve();
  else return Promise.reject();
};

export const getText = (element) => {
  var action = "l'a crée à ";

  if (element.action == "update") {
    action = "l'a modifié à";
  }

  return element.username.s__username + " " + action + " " + element.date;
};

export const toConvertDate = (date) => {
  return moment(date).format("LL H:mm");
};
export const toConvertDateTime = (date) => {
  return moment(date).format("LL H:mm");
};
export const customFilter = (input, elems, fields) => {
  if (input.length === 0) {
    return elems;
  }
  input = escapeRegExp(input);
  var words = input.split(" ");
  var regexs = [];
  for (var i = 0; i < words.length; i++) {
    var l__without = accentFold(words[i]);
    l__without = l__without.replace("è", "e");
    regexs.push(new RegExp(".*" + l__without + ".*", "i"));
  }
  var matchs = [];
  for (i = 0; i < elems.length; i++) {
    var toSearch = "";
    for (var j = 0; j < fields.length; j++) {
      if (elems[i][fields[j]]) {
        toSearch += elems[i][fields[j]];
      }

      if (j < fields.length - 1) {
        toSearch += " ";
      }
    }
    var match = true;
    for (var j = 0; j < regexs.length; j++) {
      var l__without = accentFold(toSearch);
      l__without = l__without.replace("è", "e");
      if (!regexs[j].exec(l__without)) {
        match = false;
      }
    }
    if (match) {
      matchs.push(elems[i]);
    }
  }

  return matchs;
};

const escapeRegExp = (str) => {
  return str.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, " ");
};

export const accentFold = (inStr) => {
  return inStr.replace(
    /([ÀÄÂàáâãäå])|([Çç])|([ÉÈÊËèéêë])|([ÎÏìíîï])|([ñ])|([ÖÔòóôõöø])|([ß])|([ÜÛùúûü])|([Ÿÿ])|([æ])/g,
    function (str, a, c, e, i, n, o, s, u, y, ae) {
      if (a) return "a";
      if (c) return "c";
      if (e) return "e";
      if (i) return "i";
      if (n) return "n";
      if (o) return "o";
      if (s) return "s";
      if (u) return "u";
      if (y) return "y";
      if (ae) return "ae";
    }
  );
};

export const toGetProviderClientArticle = () => {
  toGetArticlesList();
  toGetProviderList();
  toGetClientsList();
};
