import {
  Descriptions,
  Card,
  Button,
  Skeleton,
  Form,
  Row,
  Col,
  Typography,
  Select,
  Input,
  Collapse
} from "antd";

import { useState, useEffect } from "react";
import {
  toGetCode,
  toGetEntityList,
  toRegenerateCode,
  toUpdatePercentage,
  toAddOrUpdatePreferences,
  toGetPreferences,
  toUpdateMax
} from "../Compte/Api";
import cookie from "react-cookies";

import {
  ReloadOutlined,
  SaveOutlined,
  MailOutlined,
  HomeOutlined,
  PhoneOutlined,
  FacebookFilled
} from "@ant-design/icons";
import Preferences from "../../Models/Preferences";
import { successMsg } from "../../Utils/Utils";
import history from "../../Utils/History";
const { Panel } = Collapse;

const { Option } = Select;
const { TextArea } = Input;

const Parameters = ({}) => {
  const [loading, setLoading] = useState(true);
  const [form] = Form.useForm();
  const [code, setCode] = useState(null);
  const [percentage, setPercentage] = useState(null);
  const [max, setMax] = useState(null);
  const [entitys, setEntitys] = useState([]);
  const [id, setId] = useState(-1);

  useEffect(() => {
    if (cookie.load("role") != "Super Admin") history.push("/");
    form.setFieldsValue(new Preferences());
    toGetCode().then((res) => {
      setCode(res.code);
      setPercentage(res.percentage);
      setMax(res.max);
    });
    toGetEntityList().then((res) => {
      setEntitys(res);
    });

    setLoading(false);
  }, []);

  const toRegenerate = () => {
    toRegenerateCode(code).then((res) => {});
  };

  const onChangeEntity = (e) => {
    toGetPreferences(e).then((res) => {
      if (null == res) {
        form.setFieldsValue(new Preferences());
      } else {
        form.setFieldsValue(res);
      }
    });
    setId(e);
  };

  const toSave = () => {
    if (id != -1) {
      let data = form.getFieldsValue();
      data.l__entityId = id;
      toAddOrUpdatePreferences(data).then((res) => {
        successMsg("Preferences enregistrées avec succées!");
      });
    }
  };

  return (
    <>
      <Collapse>
        <Panel
          header={<Typography.Title level={5}>Paramètres</Typography.Title>}
          key="1"
        >
          <Card>
            <Skeleton active loading={loading} />

            <Descriptions bordered>
              <Descriptions.Item span={3} label="Code Admin">
                {null != code && (
                  <>
                    <input
                      style={{ fontSize: "24px", fontWeight: "bold" }}
                      value={code}
                      onChange={(e) => setCode(e.target.value)}
                    />

                    <Button
                      style={{ marginLeft: "5%" }}
                      icon={<ReloadOutlined />}
                      onClick={(e) => toRegenerate()}
                      type="primary"
                    >
                      valider
                    </Button>
                  </>
                )}
              </Descriptions.Item>
              <Descriptions.Item span={3} label="Marge minimum">
                {null != percentage && (
                  <>
                    <input
                      type="number"
                      min="0"
                      style={{ fontSize: "24px", fontWeight: "bold" }}
                      value={percentage}
                      onChange={(e) => setPercentage(e.target.value)}
                    />

                    <Button
                      style={{ marginLeft: "5%" }}
                      icon={<ReloadOutlined />}
                      onClick={(e) => toUpdatePercentage(percentage)}
                      type="primary"
                    >
                      valider
                    </Button>
                  </>
                )}
              </Descriptions.Item>
              <Descriptions.Item
                span={3}
                label="Maximum paiement fournisseur par jour"
              >
                {null != percentage && (
                  <>
                    <input
                      type="number"
                      min="0"
                      style={{ fontSize: "24px", fontWeight: "bold" }}
                      value={max}
                      onChange={(e) => setMax(e.target.value)}
                    />

                    <Button
                      style={{ marginLeft: "5%" }}
                      icon={<ReloadOutlined />}
                      onClick={(e) => toUpdateMax(max)}
                      type="primary"
                    >
                      valider
                    </Button>
                  </>
                )}
              </Descriptions.Item>
            </Descriptions>
          </Card>
        </Panel>
        <Panel
          header={
            <Typography.Title level={5}>
              Footer de la page(entête)
            </Typography.Title>
          }
          key="2"
        >
          <Row>
            <Col span="12"></Col>
          </Row>
          <Card>
            <p>
              Si vous voulez imprimer votre document(devis, facture...) en
              utilisant un papier blanc, les informations suivantes vont être
              ajoutés en bas de la page.
            </p>
            <span>Choisir l'entité: </span>

            <Select
              showSearch
              style={{ width: 200 }}
              optionFilterProp="children"
              onChange={(e) => onChangeEntity(e)}
              filterSort={(optionA, optionB) =>
                optionA.children
                  .toLowerCase()
                  .localeCompare(optionB.children.toLowerCase())
              }
            >
              {entitys.map((element) => (
                <Option value={element.id}>{element.title}</Option>
              ))}
            </Select>

            <Form
              form={form}
              layout="vertical"
              name="basic"
              initialValues={{ remember: true }}
            >
              <Row gutter={24}>
                <Col md={24} sm={24}>
                  <Row gutter={24}>
                    <Col span={12}>
                      <Form.Item label="Adresse" name="s__adress">
                        <TextArea
                          autoSize
                          prefix={<HomeOutlined />}
                          disabled={id == -1}
                        />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row gutter={24}>
                    <Col span={8}>
                      <Form.Item label="Téléphone Fixe" name="s__phone">
                        <Input prefix={<PhoneOutlined />} disabled={id == -1} />
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item label="Mobile" name="s__mobile">
                        <Input prefix={<PhoneOutlined />} disabled={id == -1} />
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item label="Fax" name="s__fax">
                        <Input disabled={id == -1} />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row gutter={24}>
                    <Col span={8}>
                      <Form.Item label="Matricule Fiscale" name="s__mF">
                        <Input disabled={id == -1} />
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item label="Registre de commerce" name="s__rC">
                        <Input disabled={id == -1} />
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item label="Compte bancaire" name="s__cCB">
                        <Input disabled={id == -1} />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={24}>
                    <Col span={8}>
                      <Form.Item label="Email" name="s__email">
                        <Input disabled={id == -1} prefix={<MailOutlined />} />
                      </Form.Item>
                    </Col>

                    <Col span={8}>
                      <Form.Item label="Facebook" name="s__fB">
                        <Input
                          disabled={id == -1}
                          prefix={<FacebookFilled />}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Button
                icon={<SaveOutlined />}
                disabled={id == -1}
                onClick={(e) => toSave()}
                type="primary"
              >
                Enregistrer
              </Button>
            </Form>
          </Card>
        </Panel>
      </Collapse>
    </>
  );
};

export default Parameters;
